import styled from '@emotion/styled'
import { ReactComponent as ArrowSvg } from '../../assets/arrowButton.svg';

export const HomeWrapper = styled.div`
margin-left: 6.25rem;
margin-right: 6.25rem;
margin-top: 12.5rem;
`;
export const Title = styled.h1`
margin-top: 5.625rem;
`;
export const HomeWrapperImage = styled.div`
justify-content: flex-end;
align-items: center;
position: relative;
max-width: 100%;
aspect-ratio: 16/3;
margin-top: 3.5rem;
display: flex;
flex-direction: column;
gap:3rem;
`;
export const Image = styled.img`
width: 100%;
height: auto;
object-fit: cover;
position: absolute;
top: 0;
left: 0;
z-index: 1;
`;
export const TextOverlay = styled.div`
z-index: 2;
font-size: 2rem;
position: absolute;
right: 2rem;
color: white;
text-align: left;
`;
export const ServicesWrapper = styled.div`
width: 100%;
display: flex;
margin-top: 25vh;
flex-direction: row;
margin-bottom:8.75rem;
`
export const ServiceTitle = styled.div`
font-size: 2.5rem;
padding: 2.5rem 0;
font-weight: 500;
align-items: center;
flex: 1;
`;
export const WrapperCards = styled.div`
display: flex;
flex:3;
flex-direction: column;
justify-content: space-between;
gap:3.75rem;
padding: 1.5rem;
`;
export const CardWrapper = styled.div`
padding: 1.5rem;
border-radius:0.75rem;
border: 2px solid #FFFFFF;
&:hover {
    border: 2px solid #000000;
}
`;
export const CardTitle = styled.div`
font-family: GT Eesti Pro Display;
font-weight: 500;
font-size: 2.5rem;
align-items: center;
`;

export const CardDiscription = styled.div`
font-weight: 400;
margin-top: 1.5rem;
margin-bottom: 2.5rem;
font-size: 1.75rem;
align-items: center;
`;

export const CardButton = styled.button`
display: flex;
justify-content: center;
align-items: center;
background-color: transparent;
width: 10rem;
border-radius: 8px;
font-weight: 500;
font-size:1.125rem;
padding:1rem 0.8rem;
height: 3rem;
&:hover {
    background-color: #000000;
    color: #FFFFFF;
    cursor: pointer;
        & svg {
            & path{
                stroke: #FFFFFF;
                }
            }
        }
&:active {
    background-color: #000000;
    color: #FFFFFF;
    & svg {
        & path{
            stroke: #FFFFFF;
            }
        }
    }    
`;
export const StyledArrow = styled(ArrowSvg)`

`;