import { Header } from "./Header"
import { Footer } from "./Footer"
import { Discription, GetInTouchWrapper, TextWrapper, Title } from "./styles/GetInTouchStyle"
import { useLocation } from "react-router-dom";
import { RefObject, useEffect, useRef, useState } from "react";
import { EmailForm } from "./EmailForm";

export const GetInTouch = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    const Ref = useRef(null);
    
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>} />
            <GetInTouchWrapper>
                <TextWrapper>
                    <Title>Обсудить проект</Title>
                    <Discription>Опишите коротко проект или задачу и мы свяжемся с вами в течении суток. Если вы по какой-то причине не получили от нас ответ, пожалуйста, повторите запрос на <div style={{ color: '#538EFF' }}>hello@docster.ru</div></Discription>
                </TextWrapper>
                <EmailForm/>
            </GetInTouchWrapper>
            <Footer />
        </>
    )
};