import { 
    HomeWrapper,
    Title,
    HomeWrapperImage,
    Image, 
    TextOverlay, 
    WrapperCards, 
    CardWrapper, 
    CardTitle, 
    CardDiscription, 
    CardButton, 
    StyledArrow,
    ServicesWrapper,
    ServiceTitle
} from './styles/HomeStyle'
import { useNavigate } from 'react-router-dom'
import { Header } from './Header'
import homeTitle from '../assets/homeTitle.svg'
import { Footer } from './Footer'
import { ContactUs } from './ContactUs'
import { RefObject, useRef } from 'react'

export const Home = () => {
    const Ref = useRef(null);
    const navigate = useNavigate();
    const handleBranding = () => {
        navigate('/branding');
    }
    const handleAnalytics = () => {
        navigate('/analytics');
    };
    const handleArchitecure = () => {
        navigate('/architecture');
    }
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>}/>
            <HomeWrapper>
                <Title>Комплексные решения<br />для здравоохранения</Title>
                <HomeWrapperImage>
                    <Image src={homeTitle} />
                    <TextOverlay ref={Ref}>Помогаем клиникам адаптироваться к<br />условиям рынка, разрабатывать и<br />реализовывать стратегии,<br />способствующие устойчивому развитию</TextOverlay>
                </HomeWrapperImage>
                <ServicesWrapper id='services'>
                    <ServiceTitle>Услуги</ServiceTitle>
                    <WrapperCards>
                        <CardWrapper>
                            <CardTitle>Архитектура, проектирование, интерьеры</CardTitle>
                            <CardDiscription>Создаем уютные и функциональные пространства для пациентов и персонала</CardDiscription>
                            <CardButton onClick={handleArchitecure}>подробнее<StyledArrow/></CardButton>
                        </CardWrapper>
                        <CardWrapper>
                            <CardTitle>Брендинг, позиционирование, корпоративный дизайн</CardTitle>
                            <CardDiscription>Развиваем сильные бренды, которые вызывают доверие и привлекают пациентов</CardDiscription>
                            <CardButton onClick={handleBranding}>подробнее<StyledArrow/></CardButton>
                        </CardWrapper>
                        <CardWrapper>
                            <CardTitle>Исследования, аналитика, стратегия</CardTitle>
                            <CardDiscription>Стремимся к созданию ценных аналитических инструментов и стратегий, направленных на оптимизацию бизнес-процессов и повышение эффективности работы наших клиентов</CardDiscription>
                            <CardButton onClick={handleAnalytics}>подробнее<StyledArrow/></CardButton>
                        </CardWrapper>
                        <CardWrapper>
                            <CardTitle>Цифровая трансформация</CardTitle>
                            <CardDiscription>Внедряем современные технологии для улучшения качества медицинской помощи, повышения операционной эффективности и удовлетворенности пациентов</CardDiscription>
                            <CardButton>подробнее<StyledArrow/></CardButton>
                        </CardWrapper>
                    </WrapperCards>
                </ServicesWrapper>
            </HomeWrapper>
            <ContactUs />
            <Footer />
        </>
    )
}
