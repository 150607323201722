import { 
    Wrapper, 
    ToolbarWrapper, 
    ToolbarButton, 
    LogoWrapper, 
    LogoImage, 
    ButtonDiscussion 
} from './styles/HeaderStyle';
import logo from '../assets/logo.svg'
import { useNavigate } from 'react-router-dom';
import { FC, RefObject, useEffect, useState } from 'react';


interface ScrollComponentProps {
    myRef?: RefObject<HTMLDivElement>;
}

export const Header: FC<ScrollComponentProps> = (props: ScrollComponentProps) => {

    const navigate = useNavigate();
    const [isScrolled, setIsScrolled] = useState(false);
    
    useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const handleHome = () => {
        navigate('/');
    }
    const handleSctollToServices = () => {
        navigate('/');
        if (props.myRef) {
            if (props.myRef.current) {
                props.myRef.current.scrollIntoView({behavior: 'smooth' });
            }
        }
    };
    const handleAbout = () => {
        navigate('/about');
    };
    const handleContacts = () => {
        navigate('/contacts');
    };
    const handleDiscussion = () => {
        navigate('/getInTouch');
    }
    return (
        <Wrapper isScrolled={isScrolled}>
            <LogoWrapper>
                <LogoImage onClick={handleHome} src={logo}></LogoImage>
            </LogoWrapper>
            <ToolbarWrapper>
                <ToolbarButton onClick={handleSctollToServices} title='Услуги'>Услуги</ToolbarButton>
                {/* <ToolbarButton title='Портфолио'>Портфолио</ToolbarButton> */}
                <ToolbarButton onClick={handleAbout} title='О нас'>О нас</ToolbarButton>
                <ToolbarButton onClick={handleContacts} title='Контакты'>Контакты</ToolbarButton>
                <ButtonDiscussion onClick={handleDiscussion} title='Обсудить проект'>Обсудить проект</ButtonDiscussion>
            </ToolbarWrapper>
        </Wrapper>
    )
}
