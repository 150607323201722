import { 
    AnalyticsWrapper, 
    TextWrapper, 
    Title, 
    Discription, 
    ContainerColumn, 
    Container, 
    ContainerDiscription, 
    ContainerTitle, 
    LogoWrapper, 
    Image 
} from "./styles/AnalyticsStyle";
import { Footer } from "./Footer";
import { ContactUs } from "./ContactUs";
import circle from '../assets/circle.svg'
import { Header } from "./Header";
import { useLocation } from "react-router-dom";
import { RefObject, useEffect, useRef } from "react";

export const Analytics = () => {

    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const Ref = useRef(null);
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>} />
            <AnalyticsWrapper>
                <TextWrapper>
                    <Title>Исследования, аналитика,<br />стратегия</Title>
                    <Discription>Анализируем данные и тренды, исследуем пользовательский опыт и оцениваем удовлетворенность, чтобы помочь клиникам принимать обоснованные решения, разрабатывать и внедрять лучшие практики. Наша команда разрабатывает стратегии, которые способствуют оптимизации процессов и достижению устойчивого роста в конкурентной среде здравоохранения.</Discription>
                    <Container>
                        <ContainerColumn>
                            <ContainerTitle>Исследования</ContainerTitle>
                            <ContainerDiscription>Потребительские<br />исследования</ContainerDiscription>
                            <ContainerDiscription>Изучение рынка<br />и трендов </ContainerDiscription>
                            <ContainerDiscription>Исследования<br />операционной<br />эффективности  </ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Аналитика</ContainerTitle>
                            <ContainerDiscription>Конкурентный анализ</ContainerDiscription>
                            <ContainerDiscription>Анализ потенциала<br />инноваций</ContainerDiscription>
                            <ContainerDiscription>Прогноз возврата<br />инвестиций</ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Управление<br />клиентским<br />опытом</ContainerTitle>
                            <ContainerDiscription>Анализ пользовательского<br />опыта (CJM)</ContainerDiscription>
                            <ContainerDiscription>Дизайн интерфейсов</ContainerDiscription>
                            <ContainerDiscription>Персонализация услуг</ContainerDiscription>
                            <ContainerDiscription>Сбор обратной связи</ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Управление<br />ресурсами</ContainerTitle>
                            <ContainerDiscription>Оптимизация ресурсов</ContainerDiscription>
                            <ContainerDiscription>Планирование <br />персонала</ContainerDiscription>
                            <ContainerDiscription>Планирование<br />инфраструктуры</ContainerDiscription>
                            <ContainerDiscription>Контент для социальных<br />сетей</ContainerDiscription>
                        </ContainerColumn>
                    </Container>
                </TextWrapper>
                <LogoWrapper>
                    <Image src={circle} />
                </LogoWrapper>
            </AnalyticsWrapper>
            <ContactUs />
            <Footer />
        </>
    )
}

