import { 
    ArchitectureWrapper,
    TextWrapper,
    Title,
    Discription,
    Container,
    ContainerColumn,
    ContainerTitle,
    ContainerDiscription,
    LogoWrapper,
    Image,
} from "./styles/ArchitectureStyle";
import { Footer } from "./Footer";
import { ContactUs } from "./ContactUs";
import circle from '../assets/circle.svg'
import { Header } from "./Header";
import { useLocation } from "react-router-dom";
import { RefObject, useEffect, useRef } from "react";

export const Architecture = () => {
    
    const { pathname } = useLocation();
    const Ref = useRef(null);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>}/>
            <ArchitectureWrapper>
                <TextWrapper>
                    <Title>Архитектура, проектирование</Title>
                    <Discription>Проектируем пространства, которые снижают операционные затраты и способствуют более качественному взаимодействию между персоналом и пациентами. Наши решения способствуют оптимизации рабочих процессов и обеспечивают возможность гибкого расширения в будущем.  Доверив нам реализацию этого проекта, вы получите современное и функциональное пространство, идеально соответствующее требованиям вашей организации.</Discription>
                    <Container>
                        <ContainerColumn>
                            <ContainerTitle>Проектирование</ContainerTitle>
                            <ContainerDiscription>Исследования</ContainerDiscription>
                            <ContainerDiscription>Коммуникационная
                            стратегия</ContainerDiscription>
                            <ContainerDiscription>Маркетинговый 
                            консалтинг</ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Интерьеры</ContainerTitle>
                            <ContainerDiscription>SMM, бренд-контент</ContainerDiscription>
                            <ContainerDiscription>Рекламные кампании</ContainerDiscription>
                            <ContainerDiscription>Голос бренда</ContainerDiscription>
                            <ContainerDiscription>Дизайн на аутсорсе </ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Нейминг</ContainerTitle>
                            <ContainerDiscription>Нейминг</ContainerDiscription>
                            <ContainerDiscription>Фирменный стиль</ContainerDiscription>
                            <ContainerDiscription>Упаковка</ContainerDiscription>
                            <ContainerDiscription>Полиграфия</ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Продакшн</ContainerTitle>
                            <ContainerDiscription>Видеопродакшн</ContainerDiscription>
                            <ContainerDiscription>Фотоопродакшн</ContainerDiscription>
                            <ContainerDiscription>Контент для<br/>социальных<br/>сетей</ContainerDiscription>
                            <ContainerDiscription>Корпоративные<br/>видео</ContainerDiscription>
                        </ContainerColumn>
                    </Container>
                </TextWrapper>
                <LogoWrapper>
                    <Image src={circle} />
                </LogoWrapper>
            </ArchitectureWrapper>
            <ContactUs />
            <Footer />
        </>
    )
}

