import styled from '@emotion/styled';

export const Wrapper = styled('div', {
    shouldForwardProp: (prop) => ![ 'isScrolled'].includes(prop),
  })<{ isScrolled?: boolean }>`

  position: fixed;
  top: 0;
  left: 6.25rem;
  right: 6.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 0;
  z-index: 1000;
  backdrop-filter: ${({ isScrolled }) => (isScrolled ? 'blur(4px)' : 'none')};
`;

export const LogoWrapper = styled('div')`
height: 3.125rem;
display: flex;
gap: 0.375rem;
flex-direction: row;
`;
export const LogoImage = styled('img')`
    &:hover {
    cursor: pointer;
    }
`;
export const ToolbarWrapper = styled('div')`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 2.5rem;
align-items: center;
`;
export const ToolbarButton = styled('button')`
background-color: transparent;
border: none;
line-height: 130%;
font-weight: 400;
font-size: 1.5rem;
&:hover {
    cursor: pointer;
}
`
export const ButtonDiscussion = styled('button')`
background-color: #F1003A;
width: 13.75rem;
height: 3.125rem;
border-radius: 1.5rem;
color: white;
font-size: 1.5rem;
font-weight: 400;
border: none;
padding: 0.625rem 1rem;
&:hover {
    cursor: pointer;
}
`;