import styled from "@emotion/styled"
import { Footer } from "./Footer";
import { ContactUs } from "./ContactUs";
import circle from '../assets/circle.svg'
import { Header } from "./Header";
import { useLocation } from 'react-router-dom';
import { RefObject, useEffect, useRef } from "react";

export const Branding = () => {

    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    const BrandingWrapper = styled.div`
    margin:12.25rem 6.25rem 6.25rem 6.25rem;
    display:flex;
    flex-direction:row;
    `;
    const LogoWrapper = styled.div`
    flex:0.5;
    `;
    const Image = styled.img`
    
    `;
    const TextWrapper = styled.div`
    display:flex;
    flex-direction:column;
    flex:4;
    `;
    const TitleBranding = styled.div`
    font-family: GT Eesti Pro Display;
    font-weight:500;
    font-size:4.85rem;
    margin-bottom:2.5rem;
    `;
    const DiscriptionBranding = styled.div`
    font-family: GT Eesti Pro Text;
    font-weight:400;
    font-size:2rem;
    line-height:150%;
    margin:2.5rem 3.5rem 2.5rem 0;
    `;
    const Container = styled.div`
    margin: 7.5rem 0;
    padding-top:7.5rem;
    display:flex;
    justify-content:space-between;
    border-top:1px solid #666666;
    alingn-items: center;
    flex-direction:row;
    `;
    const ContainerColumn = styled.div`
    display:flex;
    flex-direction:column;
    `;
    const ContainerTitle = styled.div`
    font-family: GT Eesti Pro Display;
    margin:2rem 0;
    font-weight: 500;
    font-size: 2.5rem;
    align-items: center;
    line-height:110%;
    `;
    const ContainerDiscription = styled.div`
    font-family:GT Eesti Pro Text;
    margin: 0.5rem;
    color: #484848;
    font-weight: 500;
    font-size: 1.75rem;
    line-height:120%;
    align-items: center;
    `;
    const Ref = useRef(null);
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>}/>
            <BrandingWrapper>
                <TextWrapper>
                    <TitleBranding>Брендинг</TitleBranding>
                    <DiscriptionBranding>Мы помогаем медицинским учреждениям построить имидж, создать прочную репутацию и укрепить лидерские позиции в отрасли. Комплексно проектируем пользовательский опыт взаимодействия с вашим брендом.</DiscriptionBranding>
                    <DiscriptionBranding>Пациенты чаще выбирают медицинские учреждения, чей бренд ассоциируется с качеством и надежностью, а ценности и принципы совпадают с их собственными ожиданиями от медицинских услуг. В условиях высокой конкуренции на медицинском рынке сильный бренд помогает выделиться среди других учреждений.</DiscriptionBranding>
                    <Container>
                        <ContainerColumn>
                            <ContainerTitle>Стратегия</ContainerTitle>
                            <ContainerDiscription>Исследования</ContainerDiscription>
                            <ContainerDiscription>Коммуникационная<br/>стратегия</ContainerDiscription>
                            <ContainerDiscription>Маркетинговый<br/>консалтинг </ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Бренд-<br/>коммуникация</ContainerTitle>
                            <ContainerDiscription>SMM, бренд-контент</ContainerDiscription>
                            <ContainerDiscription>Рекламные кампании</ContainerDiscription>
                            <ContainerDiscription>Голос бренда</ContainerDiscription>
                            <ContainerDiscription>Дизайн на аутсорсе</ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Айдентика</ContainerTitle>
                            <ContainerDiscription>Нейминг</ContainerDiscription>
                            <ContainerDiscription>Фирменный стиль</ContainerDiscription>
                            <ContainerDiscription>Упаковка</ContainerDiscription>
                            <ContainerDiscription>Полиграфия</ContainerDiscription>
                        </ContainerColumn>
                        <ContainerColumn>
                            <ContainerTitle>Продакшн</ContainerTitle>
                            <ContainerDiscription>Видеопродакшн</ContainerDiscription>
                            <ContainerDiscription>Фотоопродакшн</ContainerDiscription>
                            <ContainerDiscription>Контент для<br/>социальных<br/>сетей</ContainerDiscription>
                            <ContainerDiscription>Корпоративные<br/>видео</ContainerDiscription>
                        </ContainerColumn>
                    </Container>
                </TextWrapper>
                <LogoWrapper>
                    <Image src={circle} />
                </LogoWrapper>
            </BrandingWrapper>
            <ContactUs />
            <Footer />
        </>
    )
}

