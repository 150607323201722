import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { Home } from './Home';
import { Branding } from './Branding';
import { Analytics } from './Analytics';
import { About } from './About';
import { Contacts } from './Contacts';
import { GetInTouch } from './GetInTouch';
import { Architecture } from './Architecture';



export const router = createBrowserRouter(
  createRoutesFromElements([
    <Route path={'/'} element={<Home/>} />,
    <Route path={'/branding'} element={<Branding/>} />,
    <Route path={'/analytics'} element={<Analytics/>} />,
    <Route path={'/about'} element={<About/>} />,
    <Route path={'/getInTouch'} element={<GetInTouch/>} />,
    <Route path={'/contacts'} element={<Contacts/>} />,
    <Route path={'/architecture'} element={<Architecture/>} />,
  ])
);
