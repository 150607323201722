import styled from "@emotion/styled"

export const AnalyticsWrapper = styled.div`
margin:12.25rem 6.25rem 6.25rem 6.25rem;
display:flex;
flex-direction:row;
`;
export const LogoWrapper = styled.div`
flex:0.5;
`;
export const Image = styled.img`

`;
export const TextWrapper = styled.div`
display:flex;
flex-direction:column;
flex:4;
`;
export const Title = styled.div`
font-family: GT Eesti Pro Display;
font-weight:500;
font-size:4.85rem;
margin-bottom:2.5rem;
`;
export const Discription= styled.div`
font-family: GT Eesti Pro Text;
font-weight:400;
font-size:2rem;
line-height:150%;
margin:2.5rem 3.5rem 2.5rem 0;
`;
export const Container = styled.div`
margin: 7.5rem 0;
padding-top:7.5rem;
display:flex;
justify-content:space-between;
border-top:1px solid #666666;
alingn-items: center;
flex-direction:row;
`;
export const ContainerColumn = styled.div`
display:flex;
flex-direction:column;
`;
export const ContainerTitle = styled.div`
font-family: GT Eesti Pro Display;
margin:2rem 0;
font-weight: 500;
font-size: 2.5rem;
align-items: center;
line-height:110%;
`;
export const ContainerDiscription = styled.div`
font-family:GT Eesti Pro Text;
margin: 0.5rem;
color: #484848;
font-weight: 500;
font-size: 1.75rem;
line-height:120%;
align-items: center;
`;