import styled from "@emotion/styled"
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useLocation } from "react-router-dom";
import { RefObject, useEffect, useRef } from "react";

export const Contacts = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    const TouchWrapper = styled.div`
    height:60vh;
    margin-top:12.25rem;
    margin-left: 6.25rem;
    `;
    const ContainerWrapper = styled.div`
    display:flex;
    height:100%;
    flex-direction:row;
    gap: 17rem;
    align-items:center;
    `;
    const Title = styled.div`
    font-family: GT Eesti Pro Display;
    font-weight:500;
    font-size:4.85rem;
    margin-bottom:2.5rem;
    `;
    const InfoWrapper = styled.div`
    display:flex;
    flex-direction:column;
    `;
    const InfoText = styled.div`
   font-family: GT Eesti Pro Display;
   line-height:1.1;
   font-weight:400;
   font-size:3.5rem;
    `;
    const Ref = useRef(null);
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>}/>
            <TouchWrapper>
                <ContainerWrapper>
                    <Title>Мы на связи</Title>
                    <InfoWrapper>
                        <InfoText>hello@docster.ru</InfoText>
                        <InfoText>tg: docster_lab</InfoText>
                    </InfoWrapper>
                </ContainerWrapper>
            </TouchWrapper>
            <Footer />
        </>

    )
}
