import styled from "@emotion/styled"

export const GetInTouchWrapper = styled.div`
margin:12.25rem 6.25rem 6.25rem 6.25rem;
display:flex;
flex-direction:row;
gap:12rem;
`;
export const TextWrapper = styled.div`
max-width:40rem;
`;
export const Title = styled.div`
font-family: GT Eesti Pro Display;
font-weight:500;
font-size:4.85rem;
margin-bottom:2.5rem;
`;
export const Discription = styled.div`
font-family: GT Eesti Pro Text;
font-weight:400;
font-size:2rem;
line-height:150%;
margin:2.5rem 3.5rem 2.5rem 0;
`;
