import styled from '@emotion/styled'
import logoDark from '../assets/logoDark.svg'

export const Footer = () => {

    const FooterWrapper = styled.footer`
    width: 100%;
    height: 370px;
    display:block;
    background-color: #000000;
    `;
    const Wrapper = styled.div`
    display:flex;
    justify-content: flex-start;
    gap: 17.5rem;
    flex-direction:row;
    padding-top:6.25rem;
    padding-left:6.25rem;
    `;
    const LogoWrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:0.75rem;
    `;
    const Logo = styled.img`
    
    `;
    const LogoTitle = styled.div`
    color:#FFFDF5;
    font-family:GT Eesti Pro Display;
    font-weight:400;
    line-height:110%;
     font-size: 1.75rem;
    `;
    const ContactsWrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:0.75rem;
    `;
    const СontactsTitle = styled.div`
    color:#FFFDF5;
    font-family:GT Eesti Pro Display;
    font-weight:500;
    line-height:110%;
     font-size: 1.75rem;
    `;
    const ContactsEmail = styled.div`
    color:#FFFDF5;
    font-family:GT Eesti Pro Text;
    font-weight:300;
    line-height:110%;
    font-size: 1.75rem;
    `;
    return (
        <FooterWrapper>
            <Wrapper>
                <LogoWrapper>
                    <Logo src={logoDark}/>
                    <LogoTitle>Докстер, 2024</LogoTitle>
                </LogoWrapper>
                <ContactsWrapper>
                    <СontactsTitle>Контакты</СontactsTitle>
                    <ContactsEmail>hello@docter.ru</ContactsEmail>
                </ContactsWrapper>
            </Wrapper>
        </FooterWrapper>
    )
}
