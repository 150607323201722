import { useState } from 'react'
import { ButtonWrapper, FormButton, FormWrapper, LableText, TextBox } from './styles/EmailFormStyle'

export const EmailForm = () => {

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await fetch('http://89.111.175.167:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Сообщение отправлено успешно!');
        setFormData({
          name: '',
          phone: '',
          email: '',
          message: '',
        });
      } else {
        alert('Ошибка при отправке сообщения.');
      }
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Произошла ошибка при отправке сообщения.');
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <LableText>Пожалуйста, представьтесь</LableText>
      <TextBox type="text" name="name" value={formData.name} onChange={handleChange} />
      <LableText>Телефон</LableText>
      <TextBox type="phone" name="phone" value={formData.phone} onChange={handleChange} />
      <LableText>Email</LableText>
      <TextBox type="email" name="email" value={formData.email} onChange={handleChange} />
      <LableText>Краткое описание задачи, сообщение</LableText>
      <TextBox name="message" value={formData.message} onChange={handleChange} />
      <ButtonWrapper>
        <FormButton type="submit">Отправить</FormButton>
      </ButtonWrapper>
    </FormWrapper>
  )
}
