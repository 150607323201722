import { AboutWrapper,TextWrapper,Discription,Title,LogoWrapper,Image  } from "./styles/AboutStyle";
import { Footer } from "./Footer";
import { ContactUs } from "./ContactUs";
import circle from '../assets/circle.svg'
import { Header } from "./Header";
import { useLocation } from "react-router-dom";
import { RefObject, useEffect, useRef } from "react";

export const About = () => {

    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    
    const Ref = useRef(null);
    return (
        <>
            <Header myRef={Ref as RefObject<HTMLDivElement>}/>
            <AboutWrapper>
                <TextWrapper>
                    <Title>Наша миссия</Title>
                    <Discription>Наша миссия — предоставлять медицинским учреждениям инновационные решения, разрабатывать и реализовывать эффективные стратегии, способствующие улучшению качества обслуживания, повышению конкурентоспособности и созданию положительного имиджа в глазах пациентов. Мы стремимся быть надежным партнером для наших клиентов, обеспечивая индивидуальный подход и поддержку на каждом этапе их развития.</Discription>
                </TextWrapper>
                <LogoWrapper>
                    <Image src={circle} />
                </LogoWrapper>
            </AboutWrapper>
            <ContactUs />
            <Footer />
        </>
    )
}

