import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ArrowSvg } from '../assets/arrow.svg';

export const ContactUs = () => {
    const navigate = useNavigate();
    const ContactUsWrapper = styled.div`
border-top:1px solid #000000;
`;
    const ContactUsTitle = styled.h1`
font-weight: 500;
font-size: 3.5rem;
`;
const ContactUs = styled.button`
display: flex;
height: 180px;
justify-content: center;
align-items: center;
width: 100%;
border: none;
background-color: transparent;
flex-direction: row;
&:hover{
    background-color: #F1003A;
    cursor: pointer;
    color:#FFFFFF;
        & svg {
            & path{
                stroke: #FFFFFF;
                }
            }
        }
&:active{
    background-color: #F1003A;
    color:#FFFFFF;
        & svg {
            & path{
                stroke: #FFFFFF;
                }
            }
        }
    }`;
const StyledArrow = styled(ArrowSvg)`
 
`;
    const handleGetInTouch = () => {
        navigate('/getInTouch');
    };
    return (
        <ContactUsWrapper>
            <ContactUs onClick={handleGetInTouch}>
                <ContactUsTitle>Напишите нам</ContactUsTitle>
                <StyledArrow/>
            </ContactUs>
        </ContactUsWrapper>
    )
}
