import styled from "@emotion/styled"

export const FormWrapper = styled.form`
display:flex;
flex-direction:column;
justify-content:space-between;
`;
export const LableText = styled.div`
font-family:GT Eesti Pro Text;
margin: 1.5rem 0 1.5rem 0;
color: #484848;
font-weight: 500;
font-size: 1.75rem;
line-height:120%;
align-items: center;
`;
export const TextBox = styled.input`
width: 100%;
padding: 10px 0;
border: none;
border-bottom: 1px solid #A6A6A6;
background-color: transparent;
font-family: GT Eesti Pro Text;
font-size: 1.5rem;
&:focus{
      outline: none;
}
`;
export const FormButton = styled.button`
width: 15rem;
height: 4rem;
margin-top: 6.25rem;
font-family: GT Eesti Pro Display;
font-size: 1.5rem;
line-height:110%;
border-radius: 8px;
background-color: transparent;
padding: 0.75rem 1rem;
    &:hover{
        cursor: pointer;
        color: white;
        background-color: #000000;
    }
    &:active{
        color: white;
        background-color: #000000;
    }
`;
export const ButtonWrapper = styled.div`
display:flex;
justify-content:flex-end;
`;